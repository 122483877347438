<template
>
  <div class="survey">
    <div class="row">
      <div class="col-lg-12" v-if="!isLoading">
        <div class="widget">
          <div class="widget-body">
            <div class="row">
              <div class="col-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.forwarding-to-tc') }}<info-box :field="'Jourformuläret  - Öppettider Vidarekoppling till TC rubrik'" /></h5>
                <select-field
                    :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                    @updateMember="updateLocalOffice"
                    :clearable="false"
                    :multiple="true"
                    :option-label="$label()"
                    db_field="tc_relations"
                    :model="localOffice"
                    :options="localOfficeFieldOptions.tc_relations"
                    field="tc_relations"
                    :label="this.$t('labels.tc-connection')"
                ></select-field>


                <text-field
                    :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="email"
                    type="text"
                    :label="this.$t('labels.agency-email')"
                ></text-field>
                <text-field
                    :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="contact_person"
                    type="text"
                    :label="this.$t('labels.contact-person-support')"
                ></text-field>
                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.urgent-tickets') }}</h5>
                <textarea-field :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                                @updateLocalOffice="updateLocalOffice" :model="localOffice" field="urgent_matters"
                                label=""></textarea-field>
              </div>
              <div class="col-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.availability') }}<info-box :field="'Jourformuläret  - Öppettider Tillgänglighet rubrik'" /></h5>

                <textarea-field
                    :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    :label="this.$t('labels.times')"
                    field="opening_hours"
                ></textarea-field>
                <textarea-field
                    :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    :label="this.$t('labels.mail-phone-frequency-check')"
                    field="mail_phone_frequency_check"
                ></textarea-field>
                <checkbox-field
                    :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                    :model="localOffice"
                    field="has_rotating_mobile"
                    :label="this.$t('labels.has-rotating-mobile')"
                ></checkbox-field>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.other') }}</h5>
                <textarea-field :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                                @updateLocalOffice="updateLocalOffice" :model="localOffice" field="other_information"
                                label=""></textarea-field>
              </div>
              <div class="col-4">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.notes') }}</h5>

                <textarea-field :editable="$store.getters.permissions.includes('office-section-openinghours-edit')"
                                @updateLocalOffice="updateLocalOffice" :model="localOffice" field="comments"
                                label=""></textarea-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import TextField from '@/components/ticket/localoffice/TextField';
import TextFieldOffice from '@/components/ticket/office/TextField';
import SelectField from '@/components/ticket/localoffice/SelectField';
import CheckboxField from '@/components/ticket/localoffice/CheckboxField';
import CheckboxFieldOffice from '@/components/ticket/office/CheckboxField';
import TextareaField from '@/components/ticket/localoffice/TextareaField';
import MemberList from '@/components/lists/member/MemberList';
import InfoBox from '@/components/InfoBox.vue';

export default {
  name: 'survey-page',
  components: {
    TextField,
    TextFieldOffice,
    TextareaField,
    SelectField,
    CheckboxField,
    CheckboxFieldOffice,
    MemberList,
    InfoBox
  },
  props: {
    id: Number,
  },

  computed: {
    localOfficeFieldOptions: function () {
      return this.$store.getters.localOfficeOptions;
    },

    sortedInformationFields() {
      const fields = this.localOffice.information_fields;

      return fields.sort(function (a, b) {
        return a.label < b.label;
      });
    },
    parentActions() {
      const actions = this.commentOptions;
      return actions.filter((item) => item.parent === null);
    },
    childActions() {
      if (this.comment.parentAction !== null) {
        let parentAction = this.comment.parentAction.id;
        const actions = this.commentOptions;
        return actions.filter((item) => item.parent === parentAction);
      } else {
        return [];
      }
    },

    childAction() {
      return this.ticketData.comments.filter((item) => item.id === this.comment.childAction.id);
    },
  },
  data() {
    return {
      informationFieldWait: false,
      comment: {
        comment: '',
        parentAction: null,
        childAction: null,
      },
      commentOptions: [],
      isLoading: true,
      localOffice: {
        label: '',
      },
      showDetailSection: 1,
    };
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getLocalOffice(to.params.id)
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    this.localOffice = null;
    BojAPI.getLocalOffice(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },
  mounted() {
    this.getActions();
  },
  methods: {
    /**
     * Update Information Field.
     *
     * @param localoffice
     * @param field
     * @param event
     */
    updateInformationField(localoffice, field, event) {
      this.informationFieldWait = true;
      if ((field.checked && field.foreign_id === null) || field.foreign_id !== null) {
        BojAPI.updateLocalOfficeInformationField(localoffice, field, event.target.value)
            .then((response) => {
              this.informationFieldWait = false;
              this.setData(response.data);
              this.$notify({
                group: 'foo',
                text: this.$t('labels.changes-saved'),
                type: 'success',
                duration: 3000,
              });
            })
            .catch((error) => {
              this.informationFieldWait = false;
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },

    /**
     * Save new comment
     */
    saveNewComment() {
      if (this.comment.parentAction == null || this.comment.childAction == null) {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.missing-forum-event'),
          type: 'error',
          duration: 3000,
        });
      } else {
        BojAPI.saveLocalOfficeComment(this.localOffice.id, this.comment)
            .then(() => {
              BojAPI.getLocalOffice(this.localOffice.id).then((response) => {
                this.setData(response.data);
              });
              this.$notify({
                group: 'foo',
                text: this.$t('labels.note-saved'),
                type: 'success',
                duration: 3000,
              });

              this.comment = {
                comment: '',
                parentAction: null,
                childAction: null,
              };
            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission-sending-ticket'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },

    /**
     * Get available actions
     */
    getActions() {
      BojAPI.getLocalOfficeActions().then((response) => {
        this.commentOptions = response.data.data;
      });
    },

    /**
     * Update Local Office
     * @param ticket
     */
    updateLocalOffice(ticket) {
      this.$emit('updateLocalOffice', ticket);
    },
    setShowDetailSection(section) {
      this.showDetailSection = section;
    },

    setData(payload) {
      this.localOffice = payload.data;
      this.isLoading = false;
    },
  },
};
</script>
